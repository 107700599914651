import { useEffect, useState } from "react";
import { GalleryTypePicker } from "../components/gallery-type-picker";
import { useGetImagesByFolder } from "../../api/gallery/useGetImagesByFolder";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Folder } from "../models/folder.model";

type Image = {
  id: number;
  url: string;
  publicId: string;
};

export const Gallery = () => {
  const { getImagesByFolder, imageState } = useGetImagesByFolder();
  const [selectedButton, setSelectedButton] = useState<Folder | null>(null);
  const [images, setImages] = useState<Image[]>([]);

  const fetchImages = async (folder: Folder) => {
    const images = await getImagesByFolder(folder.id!);
    setImages(images);
  };

  useEffect(() => {
    if (selectedButton) {
      fetchImages(selectedButton);
    }
  }, [selectedButton]);

  return (
    <div className="flex w-full mt-16 flex-col items-center">
      <h1 className="text-3xl text-gray-500 font-bold mb-6">GALLERY</h1>
      <GalleryTypePicker onButtonSelect={setSelectedButton} />
      {imageState.status !== "LOADING" && (
        <div className="w-3/4 sm:w-2/3 md:w-1/2 lg:w-3/4 mt-10">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 mb-20">
            {images.map((image) => (
              <img
                key={image.id}
                src={image.url}
                className="w-full h-52 sm:h-60 md:h-64 fit-object"
              />
            ))}
          </div>
        </div>
      )}
      {imageState.status === "LOADING" && (
        <div className="w-3/4 sm:w-2/3 md:w-1/2 lg:w-3/4 mt-10">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 mb-20">
            {Array.from({ length: 6 }).map((_, i) => (
              <Skeleton
                key={i}
                className="w-full h-52 sm:h-60 md:h-64 fit-object"
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
