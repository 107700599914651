import { useState } from "react";
import { toast } from "react-toastify";
import { useUploadImageForHomePage } from "../../../api/gallery/useUploadImageForHomePage";
import { Image } from "../../../gallery/models/image.model";
import { Button } from "../../../shared/components/button.component";
import { UploadInput } from "./upload-input.component";
import { useQueryClient } from "react-query";

export const ImageUploadFormForHomePage = () => {
  const queryClient = useQueryClient();
  const { uploadImage, uploadState } = useUploadImageForHomePage();
  const [image, setImage] = useState<Image>({ image: null });

  const handleNewImage = async () => {
    if (!image.image) {
      toast.error("Please select an image!", {
        position: "bottom-right",
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", image.image as File);

    const resp = await uploadImage(formData);
    if (resp) {
      setImage({ image: null });
      queryClient.invalidateQueries("getImagesForHomePage");
    }
  };

  return (
    <div className="flex flex-col p-5 w-full sm:w-3/4 lg:w-1/2">
      <form className="text-center">
        <UploadInput
          onChange={(value: File) => setImage({ ...image, image: value })}
          value={image.image}
          fileType="image"
          className="flex items-center justify-center w-full"
        />
        <Button
          className="bg-[#000000b3] hover:bg-[#171717b3] text-[#b0a377] mt-3 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={handleNewImage}
          disabled={uploadState.status === "LOADING"}
        >
          {uploadState.status === "LOADING" ? "Uploading..." : "Upload"}
        </Button>
      </form>
    </div>
  );
};
