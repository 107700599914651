import { toast } from "react-toastify";
import { useAxios } from "../useAxios";
import { useResponseState } from "../useResponseState";

export const useGetVideoForHomePage = () => {
  const { axios } = useAxios();
  const {
    setLoading,
    setSuccess,
    setError,
    state: videoState,
  } = useResponseState<{ id: number; publicId: string; url: string }>({
    id: -1,
    publicId: "",
    url: "",
  });

  const getVideoForHomePage = async () => {
    try {
      setLoading();
      const resp = await axios.get("/gallery/video-for-home-page");
      setSuccess();
      return resp.data;
    } catch (error: any) {
      setError();
      // toast.error(error.response.data.message, { position: "bottom-right" });
      return {};
    }
  };

  return { getVideoForHomePage, videoState };
};
