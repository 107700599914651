import { ChangeEvent } from "react";

type Props = {
  value: string;
  onChange: (value: string) => void;
};

export const AdditionalInfo = ({ value, onChange }: Props) => {
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  return (
    <textarea
      className="resize-none mb-3 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      name="additionalInfo"
      id="additionalInfo"
      value={value}
      cols={10}
      rows={5}
      placeholder="Additional info..."
      onChange={handleInputChange}
    ></textarea>
  );
};
