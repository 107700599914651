import { FaFolder, FaImage, FaPhotoVideo } from "react-icons/fa";
import { IoIosCalendar } from "react-icons/io";
import { PiCalendar } from "react-icons/pi";
import { GrServices } from "react-icons/gr";
import { QueryClient, QueryClientProvider } from "react-query";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { SidebarItem } from "../shared/components/sidebar-item.component";
import { Sidebar } from "../shared/components/sidebar.component";
import { MdOutlineDiscount } from "react-icons/md";

const queryClient = new QueryClient();

export const AdminPanel = () => {
  const location = useLocation();

  return (
    <QueryClientProvider client={queryClient}>
      <div className="flex h-screen">
        <Sidebar>
          <SidebarItem
            icon={<MdOutlineDiscount size={25} />}
            text="Discount"
            redirect="/discount"
            isActive={location.pathname === "/discount"}
          />
          <SidebarItem
            icon={<GrServices size={25} />}
            text="Service Type"
            redirect="/service-type"
            isActive={location.pathname === "/service-type"}
          />
          <SidebarItem
            icon={<FaFolder size={25} />}
            text="Folder"
            redirect="/folder"
            isActive={location.pathname === "/folder"}
          />
          <SidebarItem
            icon={<FaPhotoVideo size={25} />}
            text="Gallery Home"
            redirect="/gallery-home"
            isActive={location.pathname === "/gallery-home"}
          />
          <SidebarItem
            icon={<FaImage size={25} />}
            text="Gallery"
            redirect="/gallery-panel"
            isActive={location.pathname === "/gallery-panel"}
          />
          <SidebarItem
            icon={<IoIosCalendar size={25} />}
            text="New Appointment"
            redirect="/new-appointment"
            isActive={location.pathname === "/new-appointment"}
          />
          <SidebarItem
            icon={<PiCalendar size={25} />}
            text="Calendar"
            redirect="/calendar"
            isActive={location.pathname === "/calendar"}
          />
        </Sidebar>
        <Outlet />
        <ToastContainer />
      </div>
    </QueryClientProvider>
  );
};
