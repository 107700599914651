import { toast } from "react-toastify";
import { useAxios } from "../useAxios";
import { useResponseState } from "../useResponseState";

export const useEditAppointment = () => {
  const { axios } = useAxios();
  const {
    setLoading,
    setSuccess,
    setError,
    state: appointmentsForDayState,
  } = useResponseState<unknown>(null);

  const editAppointment = async (
    id: number,
    appointmentStatus: "CONFIRMED" | "CANCELED"
  ) => {
    try {
      setLoading();
      const resp = await axios.patch(`/appointment/${id}`, {
        status: appointmentStatus,
      });
      toast.success("Appointment edited successfully!", {
        position: "bottom-right",
      });
      setSuccess();
      return resp.data;
    } catch (error: any) {
      toast.error(error.response.data.message, { position: "bottom-right" });
      setError();
    }
  };

  return { editAppointment, appointmentsForDayState };
};
