import { toast } from "react-toastify";
import { useAxios } from "../useAxios";
import { useResponseState } from "../useResponseState";
import { Discount } from "../../admin-panel/discount/models/discount.model";

export const useCreateOrUpdateDiscount = () => {
  const { axios } = useAxios();
  const {
    setLoading,
    setSuccess,
    setError,
    state: discountState,
  } = useResponseState<unknown>(null);

  const createOrUpdateDiscount = async (discount: Discount) => {
    try {
      setLoading();
      const resp = await axios.post("discount", discount);
      toast.success("Discount created/updated successfully!", {
        position: "bottom-right",
      });
      setSuccess();
      return resp.data;
    } catch (error: any) {
      toast.error(error.response.data.message, { position: "bottom-right" });
      setError();
    }
  };

  return { createOrUpdateDiscount, discountState };
};
