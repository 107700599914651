import { toast } from "react-toastify";
import { useAxios } from "../useAxios";
import { useResponseState } from "../useResponseState";

export const useGetAllFolders = () => {
  const { axios } = useAxios();
  const {
    setLoading,
    setSuccess,
    setError,
    state: folderState,
  } = useResponseState<{ name: string }[]>([]);

  const getAllFolders = async () => {
    try {
      setLoading();
      const resp = await axios.get("/gallery/folder");
      setSuccess();
      return resp.data;
    } catch (error) {
      setError();
      toast.error("Error fetching folders!", { position: "bottom-right" });
      return [];
    }
  };

  return { getAllFolders, folderState };
};
