import { useQueryClient } from "react-query";
import { useEditAppointment } from "../../../api/appointment/useEditAppointment";
import { Appointment } from "../../../appointment/model/appointment.model";
import { getDateFormatted } from "../../../appointment/utils/get-date-formatted";
import { Button } from "../../../shared/components/button.component";
import { getStatusColor } from "../get-status-color.type";

type Props = {
  appointments: Appointment[];
};

export const AppointmentsForDay = ({ appointments = [] }: Props) => {
  const { editAppointment, appointmentsForDayState } = useEditAppointment();
  const queryClient = useQueryClient();
  const queryKey = ["appointments"];

  const handleAppointmentConfirmed = async (id: number) => {
    await editAppointment(id, "CONFIRMED");
    await queryClient.invalidateQueries(queryKey);
  };
  const handleAppointmentCanceled = async (id: number) => {
    await editAppointment(id, "CANCELED");
    await queryClient.invalidateQueries(queryKey);
  };

  return (
    <div className="overflow-y-auto grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
      {appointments.map((appointment) => (
        <div
          key={appointment.id}
          className="relative border p-4 rounded shadow-md bg-[#000000b3] w-full"
        >
          {appointment.status === "PENDING" ? (
            <div className="flex flex-col gap-3 sm:flex-row absolute top-3 right-3">
              <Button
                className="text-green-400 cursor-pointer"
                onClick={() => handleAppointmentConfirmed(appointment.id!)}
                disabled={appointmentsForDayState.status === "LOADING"}
              >
                Confirm
              </Button>
              <Button
                className="text-red-400 cursor-pointer"
                onClick={() => handleAppointmentCanceled(appointment.id!)}
                disabled={appointmentsForDayState.status === "LOADING"}
              >
                Cancel
              </Button>
            </div>
          ) : null}
          <h2
            className={`font-bold text-lg text-center ${getStatusColor(
              appointment.status!
            )}`}
          >
            {appointment.status}
          </h2>
          <h2 className="text-xl font-semibold mb-2 text-[#8b7f56]">
            {appointment.firstName} {appointment.lastName}
          </h2>
          <p className="mb-2 text-[#b0a377]">Car: {appointment.car.model}</p>
          <p className="mb-2 text-[#b0a377]">
            Service Type: {appointment.serviceType.category}
          </p>
          <p className="mb-2 text-[#b0a377]">
            Email: {appointment.email ? appointment.email : "/"}
          </p>
          <p className="mb-2 text-[#b0a377]">
            Start Date: {getDateFormatted(appointment.startDate!.toString())}
          </p>
          <p className="mb-2 text-[#b0a377] overflow-y-auto">
            Additional Info:{" "}
            {appointment.additionalInfo ? appointment.additionalInfo : "/"}
          </p>
        </div>
      ))}
    </div>
  );
};
