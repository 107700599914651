import { toast } from "react-toastify";
import { useAxios } from "../useAxios";
import { useResponseState } from "../useResponseState";

export const useDeleteFolder = () => {
  const { axios } = useAxios();
  const {
    setLoading,
    setSuccess,
    setError,
    state: deleteFolderState,
  } = useResponseState<null>(null);

  const deleteFolder = async (name: string) => {
    try {
      setLoading();
      await axios.delete("/gallery/folder", { data: { name } });
      toast.success("Folder deleted successfully!", {
        position: "bottom-right",
      });
      setSuccess();
      return true;
    } catch (error: any) {
      toast.error(error.response.data.message, {
        position: "bottom-right",
      });
      setError();
      return false;
    }
  };

  return { deleteFolder, deleteFolderState };
};
