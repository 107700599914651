import { useEffect, useState } from "react";
import { ServiceType } from "../../admin-panel/service-type/models/service-type.model";
import { useGetAllServiceTypes } from "../../api/service-type/useGetAllServiceTypes";

type Props = {
  value: ServiceType;
  onChange: (selectedServiceType: ServiceType) => void;
};

export const SelectServiceType = ({ value, onChange }: Props) => {
  const { getAllServiceTypes } = useGetAllServiceTypes();
  const [serviceTypes, setServiceTypes] = useState<ServiceType[]>([]);

  const fetchServiceTypes = async () => {
    const serviceTypes = await getAllServiceTypes();
    setServiceTypes(serviceTypes);
  };

  useEffect(() => {
    fetchServiceTypes();
  }, []);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedServiceType = JSON.parse(event.target.value);
    onChange(selectedServiceType);
  };

  return (
    <>
      <select
        className="mb-3 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="grid-state"
        value={JSON.stringify(value)}
        onChange={handleSelectChange}
      >
        <option value={-1}>---Select service type---</option>
        {serviceTypes.map((serviceType) => (
          <option key={serviceType.id} value={JSON.stringify(serviceType)}>
            {serviceType.category}
          </option>
        ))}
      </select>
    </>
  );
};
