export const SERVICE_TYPE_FORM_DEFAULT_VALUES = {
  category: "",
  duration: -1,
};

export const FOLDER_FORM_DEFAULT_VALUES = {
  name: "",
};

export const IMAGE_FORM_DEFAULT_VALUES = {
  folder: {
    name: "",
  },
  image: null,
};
