import { ChangeEvent } from "react";

type Props = {
  value: string;
  type: string;
  placeholder: string;
  onChange: (value: string) => void;
};

export const FormInput = ({ value, type, placeholder, onChange }: Props) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className="flex flex-col w-full mb-3">
      <input
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={handleInputChange}
      />
    </div>
  );
};
