import { useAxios } from "../useAxios";
import { useResponseState } from "../useResponseState";

export const useDeleteVideo = () => {
  const { axios } = useAxios();
  const {
    setLoading,
    setSuccess,
    setError,
    state: deleteVideoState,
  } = useResponseState<null>(null);

  const deleteVideo = async ({
    id,
    public_id,
  }: {
    id: number;
    public_id: string;
  }) => {
    try {
      setLoading();
      await axios.delete("/gallery/video", { data: { id, public_id } });
      setSuccess();
    } catch (error) {
      setError();
    }
  };

  return { deleteVideo, deleteVideoState };
};
