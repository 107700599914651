import { useState } from "react";
import Modal from "react-modal";
import { Outlet, useLocation } from "react-router-dom";
import "./App.css";
import { Button } from "./shared/components/button.component";
import { NavbarItemComponent } from "./shared/components/navbar-item.component";
import { Navbar } from "./shared/components/navbar.component";
import { ModalContent } from "./shared/components/modal-content.component";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Footer } from "./shared/components/footer.component";
import { MdSchedule } from "react-icons/md";

function App() {
  const location = useLocation();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  Modal.setAppElement("#root");

  return (
    <>
      <Navbar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}>
        <NavbarItemComponent
          text="Home"
          redirect="/"
          isActive={location.pathname === "/home"}
          setIsMenuOpen={setIsMenuOpen}
        />
        <NavbarItemComponent
          text="Gallery"
          redirect="/gallery"
          isActive={location.pathname === "/gallery"}
          setIsMenuOpen={setIsMenuOpen}
        />
        <NavbarItemComponent
          text="Contact"
          redirect="/contact"
          isActive={location.pathname === "/contact"}
          setIsMenuOpen={setIsMenuOpen}
        />
      </Navbar>
      <Outlet />
      <Footer />
      <Button
        className="flex items-center justify-center fixed bottom-10 right-7 bg-[#000000b3] text-[#b0a377] p-3 rounded"
        onClick={openModal}
      >
        <MdSchedule size={20} className="mr-2" /> Reserve
      </Button>
      <Modal
        className="w-4/5 md:w-2/3 h-5/6 top-1/2 left-1/2 absolute -translate-x-1/2 -translate-y-1/2 flex items-center flex-col rounded overflow-y-auto bg-[#242422e0] no-scrollbar"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      >
        <ModalContent closeModal={closeModal} />
      </Modal>
      <ToastContainer />
    </>
  );
}

export default App;
