import { useEffect, useState } from "react";
import { AppointmentsForDay } from "../components/appointments-for-day.component";
import { useGetAppointmentsForDay } from "../../../api/appointment/useGetAppointmentsForDay";
import { Appointment } from "../../../appointment/model/appointment.model";
import { SelectStatusForCalendar } from "../components/select-status-for-calendar.component";
import { UseQueryResult, useQuery } from "react-query";

type StatusType = "ALL" | "CONFIRMED" | "CANCELED" | "PENDING";

export const Calendar = () => {
  const { getAllAppointmentsForDay } = useGetAppointmentsForDay();
  const [date, setDate] = useState<Date>(new Date());
  const [status, setStatus] = useState<StatusType>("ALL");
  const [filteredAppointments, setFilteredAppointments] = useState<
    Appointment[]
  >([]);

  const {
    data: appointments,
    refetch,
  }: UseQueryResult<Appointment[], unknown> = useQuery("appointments", () =>
    getAllAppointmentsForDay(date)
  );

  useEffect(() => {
    refetch();
  }, [date, refetch]);

  useEffect(() => {
    if (appointments) {
      if (status === "ALL") {
        setFilteredAppointments(appointments);
      } else {
        setFilteredAppointments(
          appointments.filter((appointment) => appointment.status === status)
        );
      }
    }
  }, [appointments, status]);

  return (
    <div className="flex flex-col w-full items-center overflow-y-auto">
      <div className="flex flex-col items-center sm:flex-row w-4/5 justify-center my-10 gap-5">
        <input
          type="date"
          className="shadow appearance-none border rounded w-2/3 sm:w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          onChange={(e) => setDate(new Date(e.target.value))}
        />
        <SelectStatusForCalendar
          onChange={(value) => setStatus(value as StatusType)}
        />
      </div>
      {appointments ? (
        <AppointmentsForDay appointments={filteredAppointments} />
      ) : null}
    </div>
  );
};
