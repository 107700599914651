import { RouteObject } from "react-router-dom";
import App from "../App";
import { Login } from "../auth/pages/login.page";
import { AdminPanel } from "../admin-panel/pages/admin-panel.page";
import { Calendar } from "../admin-panel/calendar/pages/calendar.page";
import { ServiceType } from "../admin-panel/service-type/pages/service-type.page";
import { Contact } from "../contact/pages/contact.page";
import { Gallery } from "../gallery/pages/gallery.page";
import { Appointment } from "../admin-panel/appointment/pages/appointment.page";
import { Home } from "../home/pages/home.page";
import { GalleryAdminPanel } from "../admin-panel/gallery/pages/gallery-admin-panel.page";
import { GalleryHome } from "../admin-panel/gallery/pages/gallery-home";
import { Folder } from "../admin-panel/gallery/pages/folder-page";
import { AuthWrapper } from "../auth/components/auth-wrapper";
import { Discount } from "../admin-panel/discount/pages/discount.page";
import { Error404 } from "../shared/pages/404.page";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "gallery",
        element: <Gallery />,
      },
    ],
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "/",
    element: (
      <AuthWrapper>
        <AdminPanel />
      </AuthWrapper>
    ),
    children: [
      {
        path: "discount",
        element: <Discount />,
      },
      {
        path: "service-type",
        element: <ServiceType />,
      },
      {
        path: "folder",
        element: <Folder />,
      },
      {
        path: "gallery-home",
        element: <GalleryHome />,
      },
      {
        path: "gallery-panel",
        element: <GalleryAdminPanel />,
      },
      {
        path: "new-appointment",
        element: <Appointment />,
      },
      {
        path: "calendar",
        element: <Calendar />,
      },
    ],
  },
  {
    path: "*",
    element: <Error404 />,
  },
];
