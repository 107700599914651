import { FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoHomeSharp } from "react-icons/io5";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="flex flex-col justify-center items-center h-96 w-full bg-[#252525]">
      <div className="ml-5 md:ml-0 w-full flex flex-col sm:flex-row justify-around mb-12">
        <div className="flex flex-col mb-5 sm:mb-0">
          <h3 className="text-[#6b6957] mb-8">Master Engine</h3>
          <Link
            to="/gallery"
            className="text-[#9d9d9d] hover:text-[#e2e2e2] duration-300"
          >
            Gallery
          </Link>
          <Link
            to="/contact"
            className="text-[#9d9d9d] hover:text-[#e2e2e2] duration-300"
          >
            Contact
          </Link>
        </div>
        <div className="flex flex-col">
          <h3 className="text-[#6b6957] mb-8">Contact us</h3>
          <p className="flex items-center text-[#9d9d9d]">
            <FaPhone className="mr-3" />
            <a
              href="tel:0631234567"
              className="hover:text-[#e2e2e2] duration-300"
            >
              063 123 45 67
            </a>
          </p>
          <p className="flex items-center text-[#9d9d9d]">
            <MdEmail className="mr-3" />
            <a
              href="mailto:test@test.com"
              className="hover:text-[#e2e2e2] duration-300"
            >
              test@test.com
            </a>
          </p>
          <p className="flex items-center text-[#9d9d9d]">
            <IoHomeSharp className="mr-3" /> Vojvode Bogdana 11, Belgrade 11000
            Serbia
          </p>
        </div>
      </div>
      <div className="flex w-full justify-evenly">
        <Link to="/">
          <FaFacebookF
            className="text-[#6b6957] hover:text-[#5a5848] duration-300"
            size={60}
          />
        </Link>
        <Link to="/">
          <FaInstagram
            className="text-[#6b6957] hover:text-[#5a5848] duration-300"
            size={60}
          />
        </Link>
        <Link to="/">
          <FaYoutube
            className="text-[#6b6957] hover:text-[#5a5848] duration-300"
            size={60}
          />
        </Link>
      </div>
    </footer>
  );
};
