import { convertToHTML } from "draft-convert";
import { ContentState, EditorState } from "draft-js";
import htmltoDraft from "html-to-draftjs";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useCreateOrUpdateDiscount } from "../../../api/discount/useCreateDiscount";
import { useGetDiscount } from "../../../api/discount/useGetDiscount";
import { Button } from "../../../shared/components/button.component";
import { PulseLoader } from "react-spinners";

export const Discount = () => {
  const { getDiscount, discountState } = useGetDiscount();
  const { createOrUpdateDiscount, discountState: createOrUpdateDiscountState } =
    useCreateOrUpdateDiscount();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const fetchDiscount = async () => {
      const resp = await getDiscount();
      if (resp) {
        const contentBlock = htmltoDraft(resp.text);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setEditorState(EditorState.createWithContent(contentState));
        setIsActive(resp.isActive);
      }
    };

    fetchDiscount();
  }, []);

  const handleDiscount = async () => {
    const contentState = editorState.getCurrentContent();
    const content = convertToHTML(contentState);
    const discount = {
      text: content,
      isActive,
    };

    await createOrUpdateDiscount(discount);
  };

  return (
    <div className="flex w-full flex-col items-center overflow-y-auto">
      {discountState.status === "LOADING" ? (
        <div className="w-full h-full flex justify-center items-center">
          <PulseLoader color="#b0a377" />
        </div>
      ) : (
        <>
          <div className="w-full flex items-center justify-center">
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName mt-10"
              wrapperClassName="wrapperClassName mb-4"
              editorClassName="editorClassName"
              onEditorStateChange={setEditorState}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "list",
                  "history",
                  "emoji",
                  "remove",
                ],
                blockType: {
                  inDropdown: true,
                  options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
                },
                inline: {
                  options: ["bold", "italic"],
                },
              }}
            />
          </div>
          <label className="inline-flex items-center cursor-pointer mb-10 mt-20">
            <input
              type="checkbox"
              value=""
              checked={isActive}
              className="sr-only peer"
              onChange={(e) => setIsActive(e.target.checked)}
            />
            <div className="relative w-11 h-6 bg-gray-600 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#000000b3]"></div>
            <span className="ms-3 text-sm font-medium text-gray-600">
              Toggle discount
            </span>
          </label>
          <Button
            className="bg-[#000000b3] hover:bg-[#171717b3] text-[#b0a377] font-bold mb-5 py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={handleDiscount}
            disabled={createOrUpdateDiscountState.status === "LOADING"}
          >
            {createOrUpdateDiscountState.status === "LOADING"
              ? "Saving..."
              : "Save"}
          </Button>
        </>
      )}
    </div>
  );
};
