import * as yup from "yup";

const REQUIRED = "Field is required!";

export const APPOINTMENT_VALIDATION_SCHEMA = yup.object({
  firstName: yup.string().required(REQUIRED),
  lastName: yup.string().required(REQUIRED),
  car: yup.object().shape({
    model: yup.string().required(REQUIRED),
  }),
  serviceType: yup.object().shape({
    id: yup.number().required(REQUIRED),
    category: yup.string().required(REQUIRED),
    duration: yup.number().required(REQUIRED),
  }),
  startDate: yup.object().shape({
    justDate: yup.date().required(REQUIRED),
    dateTime: yup.date().required(REQUIRED),
  }),
});

export const APPOINTMENT_FORM_DEFAULT_VALUES = {
  firstName: "",
  lastName: "",
  car: {
    model: "",
  },
  email: "",
  serviceType: {
    id: -1,
    category: "",
    duration: 0,
  },
  startDate: null,
  additionalInfo: "",
};
