type Props = {
  onChange: (value: File) => void;
  value: File | null;
  fileType: "image" | "video";
  className: string;
};

export const UploadInput = ({
  onChange,
  value,
  fileType,
  className,
}: Props) => {
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      onChange(e.target.files[0]);
    }
  };

  return (
    <>
      <div className={`${className}`}>
        <label
          htmlFor={`dropzone-file-${fileType}`}
          className="flex flex-col items-center justify-center w-full h-48 rounded cursor-pointer bg-gray-300"
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              />
            </svg>
            <span className="mb-2 text-sm text-gray-500 font-semibold">
              Click to upload
            </span>
            <p className="text-xs text-gray-500">
              {fileType === "image" ? "PNG, JPG or JPEG" : "MP4, MOV or WMV"}
            </p>
          </div>
          <input
            id={`dropzone-file-${fileType}`}
            type="file"
            className="hidden"
            onChange={handleFileChange}
          />
        </label>
      </div>
      {value && (
        <div className="p-4 my-3 rounded bg-gray-300 w-full overflow-hidden text-ellipsis text-center">
          <p>Selected Files:</p>
          <span className="text-[#b0a377] whitespace-nowrap">{value.name}</span>
        </div>
      )}
    </>
  );
};
