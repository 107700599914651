import { useAxios } from "../useAxios";
import { useResponseState } from "../useResponseState";
import { toast } from "react-toastify";

export const useGetImagesByFolder = () => {
  const { axios } = useAxios();
  const {
    setLoading,
    setSuccess,
    setError,
    state: imageState,
  } = useResponseState<{ id: number; publicId: string; imgUrl: string }[]>([]);

  const getImagesByFolder = async (folderId: number) => {
    try {
      setLoading();
      const resp = await axios.post("/gallery/images-by-folder", { folderId });
      setSuccess();
      return resp.data;
    } catch (error) {
      setError();
      toast.error("Error fetching images!", { position: "bottom-right" });
      return [];
    }
  };

  return { getImagesByFolder, imageState };
};
