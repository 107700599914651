import { ReactNode } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";

type Props = {
  children: ReactNode;
  isMenuOpen: boolean;
  setIsMenuOpen: (value: React.SetStateAction<boolean>) => void;
};

export const Navbar = ({ children, isMenuOpen, setIsMenuOpen }: Props) => {
  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <nav className="h-12 md:h-20 flex justify-center bg-[#252525]">
      <div className="w-3/4 h-full flex items-center justify-between relative">
        <Link className="text-2xl h-full" to="/">
          <img className="w-20 md:w-48 h-full" src={logo} alt="Logo" />
        </Link>
        <ul
          className={`text-[#b0a377] absolute flex flex-col w-full top-full left-0 md:w-max md:top-0 md:relative md:flex-row justify-center items-center bg-[#252525] ${
            isMenuOpen
              ? "opacity-100 translate-y-0 transition-all duration-300"
              : "opacity-0 translate-y-[-10px] transition-all duration-300 md:opacity-100 md:translate-y-0 md:transition-none md:flex"
          }`}
        >
          {children}
        </ul>
        <GiHamburgerMenu
          className="text-[#e2e2e2] flex md:hidden"
          size={25}
          onClick={toggleMenu}
        />
      </div>
    </nav>
  );
};
