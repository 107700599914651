import { useNavigate } from "react-router-dom";
import { Car } from "../../assets/svgs/car.svg";
import { Button } from "../components/button.component";

export const Error404 = () => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate("/");
  };
  return (
    <div className="w-full h-screen flex flex-col md:flex-row md:justify-center items-center text-center">
      <Car />
      <div className="flex flex-col justify-center items-center">
        <h1>OOPS! PAGE NOT FOUND.</h1>
        <p className="my-5">The page you were looking for does not exist.</p>
        <Button
          className="flex items-center w-max justify-center bg-[#000000b3] text-[#b0a377] p-3 rounded"
          onClick={handleOnClick}
        >
          Go back to home
        </Button>
      </div>
    </div>
  );
};
