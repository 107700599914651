import { toast } from "react-toastify";
import { ServiceType } from "../../admin-panel/service-type/models/service-type.model";
import { useAxios } from "../useAxios";
import { useResponseState } from "../useResponseState";
import { Discount } from "../../admin-panel/discount/models/discount.model";

export const useGetDiscount = () => {
  const { axios } = useAxios();
  const {
    setLoading,
    setSuccess,
    setError,
    state: discountState,
  } = useResponseState<Discount | null>(null);

  const getDiscount = async () => {
    try {
      setLoading();
      const resp = await axios.get("/discount");
      setSuccess();
      return resp.data;
    } catch (error: any) {
      toast.error(error.response.data.message, { position: "bottom-right" });
      setError();
    }
  };

  return { getDiscount, discountState };
};
