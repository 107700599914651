import { FolderManager } from "../components/folder-manager.component";

export const Folder = () => {
  return (
    <div className="flex w-full flex-col items-center overflow-y-auto">
      <h1 className="text-gray-600 font-bold text-center text-xl sm:text-2xl md:text-3xl lg:text-4xl my-10">
        Folder
      </h1>
      <FolderManager />
    </div>
  );
};
