import { PulseLoader } from "react-spinners";
import { useDeleteFolder } from "../../../api/gallery/useDeleteFolder";
import { Button } from "../../../shared/components/button.component";

type Props = {
  folders: { name: string; deleting: boolean }[];
  setFolders: (folders: any) => void;
  folderState: { status: string };
};

export const Folder = ({ folders, setFolders, folderState }: Props) => {
  const { deleteFolder } = useDeleteFolder();

  const handleFolderDelete = async (folder: { name: string }) => {
    const index = folders.findIndex((f) => f.name === folder.name);
    if (index !== -1) {
      const updatedFolders = [...folders];
      updatedFolders[index].deleting = true;
      setFolders(updatedFolders);

      const resp = await deleteFolder(folder.name);
      if (resp) {
        setFolders(updatedFolders.filter((f) => f.name !== folder.name));
      } else {
        updatedFolders[index].deleting = false;
        setFolders(updatedFolders);
      }
    }
  };

  return (
    <div className="flex flex-wrap justify-center items-center">
      {folderState.status === "LOADING" ? (
        <PulseLoader className="m-2" color="#6b7280" />
      ) : (
        folders.map((folder, i) => (
          <div
            key={i}
            className="flex flex-col min-w-52 m-2 p-2 rounded bg-[#000000b3]"
          >
            <p className="mb-5 text-center text-gray-200">{folder.name}</p>
            <Button
              className="text-red-400 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={() => handleFolderDelete(folder)}
              disabled={folder.deleting}
            >
              {folder.deleting ? "Deleting..." : "Delete"}
            </Button>
          </div>
        ))
      )}
    </div>
  );
};
