import { AppointmentForm } from "../../appointment/components/appointment-form.component";
import { IoMdClose } from "react-icons/io";

type Props = {
  closeModal: () => void;
};

export const ModalContent = ({ closeModal }: Props) => {
  return (
    <>
      <div className="relative p-10 bg-[#252525] w-full text-center text-3xl text-[#b0a377] rounded-t">
        New Appointment
        <IoMdClose
          size={30}
          className="absolute top-3 right-3 cursor-pointer"
          onClick={closeModal}
        />
      </div>
      <AppointmentForm />
    </>
  );
};
