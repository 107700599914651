import { toast } from "react-toastify";
import { useAxios } from "../useAxios";
import { useResponseState } from "../useResponseState";

export const useGetImagesForHomePage = () => {
  const { axios } = useAxios();
  const {
    setLoading,
    setSuccess,
    setError,
    state: imageState,
  } = useResponseState<{ imgUrl: string; publicId: string }[]>([]);

  const getImagesForHomePage = async () => {
    try {
      setLoading();
      const resp = await axios.get("/gallery/images-for-home-page");
      setSuccess();
      return resp.data;
    } catch (error: any) {
      setError();
      // toast.error(error.response.data.message, { position: "bottom-right" });
      return [];
    }
  };

  return { getImagesForHomePage, imageState };
};
