import { useState } from "react";
import { toast } from "react-toastify";
import { useUploadVideoForHomePage } from "../../../api/gallery/useUploadVideoForHomePage";
import { Video } from "../../../gallery/models/video.model";
import { Button } from "../../../shared/components/button.component";
import { UploadInput } from "./upload-input.component";
import { useQueryClient } from "react-query";

export const VideoUploadFormForHomePage = () => {
  const queryClient = useQueryClient();
  const { uploadVideo, uploadState } = useUploadVideoForHomePage();
  const [video, setVideo] = useState<Video>({ video: null });

  const handleNewVideo = async () => {
    if (!video.video) {
      toast.error("Please select a video to upload!", {
        position: "bottom-right",
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", video.video as File);

    const resp = await uploadVideo(formData);
    if (resp) {
      setVideo({ video: null });
      queryClient.invalidateQueries("getVideoForHomePage");
    }
  };

  return (
    <div className="flex flex-col p-5 w-full sm:w-3/4 lg:w-1/2">
      <form className="text-center">
        <UploadInput
          onChange={(value: File) => setVideo({ ...video, video: value })}
          value={video.video}
          fileType="video"
          className="flex items-center justify-center w-full"
        />
        <Button
          className="bg-[#000000b3] hover:bg-[#171717b3] text-[#b0a377] mt-3 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={handleNewVideo}
          disabled={uploadState.status === "LOADING"}
        >
          {uploadState.status === "LOADING" ? "Uploading..." : "Upload"}
        </Button>
      </form>
    </div>
  );
};
