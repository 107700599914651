import { useEffect, useState } from "react";
import { useGetImagesForHomePage } from "../../../api/gallery/useGetImagesForHomePage";
import Skeleton from "react-loading-skeleton";
import { Button } from "../../../shared/components/button.component";
import { useDeleteImage } from "../../../api/gallery/useDeleteImage";
import { useQuery } from "react-query";

type Image = {
  id: number;
  url: string;
  public_id: string;
};

export const ImagesForHomePage = () => {
  const { getImagesForHomePage, imageState } = useGetImagesForHomePage();
  const { deleteImage, deleteImageState } = useDeleteImage();
  const [images, setImages] = useState<Image[]>([]);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);

  const { data: imgs } = useQuery("getImagesForHomePage", getImagesForHomePage);

  const fetchImages = async () => {
    const resp = await getImagesForHomePage();
    setImages(resp);
  };

  const deleteImageHandler = async (image: Image) => {
    await deleteImage(image);
  };

  useEffect(() => {
    if (deleteImageState.status === "SUCCESS") {
      setImages(
        images.filter((image) => image.public_id !== selectedImage!.public_id)
      );
      setSelectedImage(null);
    }
  }, [deleteImageState.status]);

  useEffect(() => {
    fetchImages();
  }, []);

  useEffect(() => {
    setImages(imgs);
  }, [imgs]);

  return (
    <>
      <div className="w-3/4 sm:w-2/3 md:w-1/2 lg:w-3/4">
        {imageState.status === "SUCCESS" && (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mb-20">
            {images &&
              images.map((image) => (
                <img
                  key={image.id}
                  src={image.url}
                  className={`w-full h-52 sm:h-60 md:h-64 fit-object ${
                    selectedImage?.public_id === image.public_id &&
                    "border-8 border-[#b0a377]"
                  }`}
                  onClick={() => setSelectedImage(image)}
                />
              ))}
          </div>
        )}
        {imageState.status === "LOADING" && (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mb-20">
            {Array.from({ length: 6 }).map((_, i) => (
              <Skeleton key={i} className="w-full h-52 sm:h-60 md:h-64" />
            ))}
          </div>
        )}
      </div>
      {selectedImage ? (
        <Button
          className="bg-[#000000b3] text-[#b0a377] p-3 rounded mb-10"
          onClick={() => deleteImageHandler(selectedImage)}
        >
          {deleteImageState.status === "LOADING" ? "Deleting..." : "Delete"}
        </Button>
      ) : null}
    </>
  );
};
