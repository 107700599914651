import { useEffect, useState } from "react";
import { useGetVideoForHomePage } from "../../../api/gallery/useGetVideoForHomePage";
import { useDeleteVideo } from "../../../api/gallery/useDeleteVideo";
import Skeleton from "react-loading-skeleton";
import { Button } from "../../../shared/components/button.component";
import { useQuery } from "react-query";

type Video = {
  id: number;
  url: string;
  public_id: string;
};

export const VideoForHomePage = () => {
  const { getVideoForHomePage, videoState } = useGetVideoForHomePage();
  const { deleteVideo, deleteVideoState } = useDeleteVideo();
  const [video, setVideo] = useState<Video>();
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);

  const { data: videoData } = useQuery(
    "getVideoForHomePage",
    getVideoForHomePage
  );

  const fetchVideo = async () => {
    const resp = await getVideoForHomePage();
    setVideo(resp);
  };

  const deleteVideoHandler = async (video: Video) => {
    await deleteVideo(video);
  };

  useEffect(() => {
    if (deleteVideoState.status === "SUCCESS") {
      setVideo(undefined);
      setSelectedVideo(null);
    }
  }, [deleteVideoState.status]);

  useEffect(() => {
    fetchVideo();
  }, []);

  useEffect(() => {
    setVideo(videoData);
  }, [videoData]);

  return (
    <>
      <div className="w-3/4 sm:w-2/3 lg:w-3/4 flex justify-center mb-20">
        {videoState.status !== "LOADING" &&
          video &&
          Object.keys(video).length > 0 && (
            <video
              src={video.url}
              className={`w-full lg:w-1/2 h-52 sm:h-60 md:h-64 fit-object ${
                selectedVideo?.id === video.id && "border-8 border-[#b0a377]"
              }`}
              width="w-full"
              autoPlay
              loop
              muted
              onClick={() => setSelectedVideo(video)}
            />
          )}
        {videoState.status === "LOADING" && (
          <div className="w-full lg:w-2/3 h-52 sm:h-60 md:h-64">
            <Skeleton className="w-full h-full" />
          </div>
        )}
      </div>
      {selectedVideo && (
        <Button
          className="bg-[#000000b3] text-[#b0a377] p-3 rounded mb-10"
          onClick={() => deleteVideoHandler(selectedVideo)}
        >
          {deleteVideoState.status === "LOADING" ? "Deleting..." : "Delete"}
        </Button>
      )}
    </>
  );
};
