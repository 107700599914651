import { useState } from "react";
import { PulseLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useCreateFolder } from "../../../api/gallery/useCreateFolder";
import { Folder } from "../../../gallery/models/folder.model";
import { Button } from "../../../shared/components/button.component";
import { FormInput } from "../../../shared/components/form-input.component";
import { FOLDER_FORM_DEFAULT_VALUES } from "../../admin-panel.constants";

type Props = {
  onFolderCreated: () => void;
};

export const FolderInputForm = ({ onFolderCreated }: Props) => {
  const { createFolder, folderState } = useCreateFolder();
  const [folder, setFolder] = useState<Folder>(FOLDER_FORM_DEFAULT_VALUES);

  const handleNewFolder = async () => {
    const trimmedName = folder.name.trim();
    if (trimmedName === "") {
      toast.error("Please enter a category!", {
        position: "bottom-right",
      });
      return;
    }

    const success = await createFolder({ name: trimmedName });
    if (success) {
      setFolder(FOLDER_FORM_DEFAULT_VALUES);
      onFolderCreated();
    }
  };

  return (
    <div className="flex flex-col p-5 w-full sm:w-3/4 lg:w-1/2">
      <form className="text-center">
        <FormInput
          value={folder.name}
          type="text"
          placeholder="Category"
          onChange={(value) => setFolder({ ...folder, name: value })}
        />
        <Button
          className="bg-[#000000b3] hover:bg-[#171717b3] text-[#b0a377] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={handleNewFolder}
          disabled={folderState.status === "LOADING"}
        >
          {folderState.status === "LOADING" ? (
            <PulseLoader color="#b0a377" />
          ) : (
            "Add category"
          )}
        </Button>
      </form>
    </div>
  );
};
