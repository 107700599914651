import { toast } from "react-toastify";
import { ServiceType } from "../../admin-panel/service-type/models/service-type.model";
import { useAxios } from "../useAxios";
import { useResponseState } from "../useResponseState";

export const useCreateServiceType = () => {
  const { axios } = useAxios();
  const {
    setLoading,
    setSuccess,
    setError,
    state: serviceTypeState,
  } = useResponseState<unknown>(null);

  const createServiceType = async (serviceType: ServiceType) => {
    try {
      setLoading();
      const resp = await axios.post("service-type", serviceType);
      toast.success("Service type created successfully!", {
        position: "bottom-right",
      });
      setSuccess();
      return resp.data;
    } catch (error: any) {
      toast.error(error.response.data.message, { position: "bottom-right" });
      setError();
    }
  };

  return { createServiceType, serviceTypeState };
};
