import { useEffect, useState } from "react";
import { useGetAllFolders } from "../../api/gallery/useGetAllFolders";
import { Button } from "../../shared/components/button.component";
import PulseLoader from "react-spinners/PulseLoader";
import { Folder } from "../models/folder.model";

type Props = {
  onButtonSelect: (selectedButton: Folder) => void;
};

export const GalleryTypePicker = ({ onButtonSelect }: Props) => {
  const { getAllFolders, folderState } = useGetAllFolders();
  const [folders, setFolders] = useState<Folder[]>([]);
  const [selectedButton, setSelectedButton] = useState<Folder | null>(null);

  const fetchFolders = async () => {
    const response = await getAllFolders();
    setFolders(response);
  };

  useEffect(() => {
    fetchFolders();
  }, []);

  useEffect(() => {
    if (folders.length > 0) {
      setSelectedButton(folders[0]);
      onButtonSelect(folders[0]);
    }
  }, [folders]);

  const handleButtonClick = (folder: Folder) => {
    setSelectedButton(folder);
    onButtonSelect(folder);
  };

  return (
    <>
      <div className="flex max-w-[90%] lg:max-w-[60%] text-nowrap overflow-auto">
        {folders.map((folder, i) => (
          <Button
            key={i}
            className={`m-2 p-2 rounded ${
              selectedButton?.id === folder.id
              ? "bg-[#000000b3] text-[#b0a377]"
                : ""
            }`}
            onClick={() => handleButtonClick(folder)}
          >
            {folder.name}
          </Button>
        ))}
        {folderState.status === "LOADING" && (
          <PulseLoader className="m-2" color="#6b7280" />
        )}
      </div>
    </>
  );
};
