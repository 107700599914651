import { useQuery } from "react-query";
import { useGetAllServiceTypes } from "../../../api/service-type/useGetAllServiceTypes";
import { useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import { Button } from "../../../shared/components/button.component";
import { useDeleteServiceType } from "../../../api/service-type/useDeleteServiceType";
import { ServiceType } from "../models/service-type.model";

const convertDurationToHoursAndMinutes = (duration: number): string => {
  const hours = Math.floor(duration);
  const minutes = (duration - hours) * 60;
  if (hours === 0) return `${minutes} minutes`;
  if (minutes === 0) return `${hours} hours`;
  return `${hours} hours ${minutes} minutes`;
};

export const AllServiceTypes = () => {
  const { getAllServiceTypes, serviceTypeState } = useGetAllServiceTypes();
  const { deleteServiceType, serviceTypeState: deleteServiceTypeState } =
    useDeleteServiceType();
  const [serviceTypes, setServiceTypes] = useState<ServiceType[]>([]);

  const fetchServiceTypes = async () => {
    const resp = await getAllServiceTypes();
    return resp;
  };

  const { data } = useQuery<ServiceType[]>(
    "allServiceTypes",
    fetchServiceTypes
  );

  useEffect(() => {
    if (data) {
      setServiceTypes(data);
    }
  }, [data]);

  const handleServiceTypeDelete = async (id: number) => {
    const resp = await deleteServiceType(id);
    if (resp) {
      setServiceTypes(serviceTypes.filter((s) => s.id !== id));
    }
  };

  return (
    <div className="flex flex-wrap justify-center items-center">
      {serviceTypeState.status === "LOADING" ? (
        <PulseLoader className="m-2" color="#6b7280" />
      ) : (
        <>
          {serviceTypes.length > 0 ? (
            serviceTypes.map((serviceType, i) => (
              <div
                key={i}
                className="flex flex-col min-w-56 m-2 p-2 rounded bg-[#000000b3]"
              >
                <p className="mb-5 text-center text-gray-200">
                  {serviceType.category}
                </p>
                <p className="mb-5 text-center text-gray-200">
                  Duration:{" "}
                  {convertDurationToHoursAndMinutes(serviceType.duration)}
                </p>
                <Button
                  className="text-red-400 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => handleServiceTypeDelete(serviceType.id!)}
                  disabled={serviceTypeState.status === "LOADING"}
                >
                  {deleteServiceTypeState.status === "LOADING"
                    ? "Deleting..."
                    : "Delete"}
                </Button>
              </div>
            ))
          ) : (
            <p>No service types available.</p>
          )}
        </>
      )}
    </div>
  );
};
