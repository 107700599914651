import { useEffect, useState } from "react";
import { useGetDiscount } from "../../api/discount/useGetDiscount";
import { Discount as DiscountModel } from "../../admin-panel/discount/models/discount.model";

export const Discount = () => {
  const { getDiscount, discountState } = useGetDiscount();
  const [discount, setDiscount] = useState<DiscountModel | null>(null);

  useEffect(() => {
    const fetchDiscount = async () => {
      const resp = await getDiscount();
      if (resp) {
        setDiscount(resp);
      }
    };

    fetchDiscount();
  }, []);
  return discountState.status === "SUCCESS" && discount?.isActive ? (
    <div
      className="w-full py-12 text-center flex flex-col justify-center items-center bg-[#474d4b] border-8 border-[#5f6865] text-white"
      dangerouslySetInnerHTML={{ __html: discount.text }}
    />
  ) : null;
};
