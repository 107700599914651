import { ReactNode, createContext, memo, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Button } from "../../../shared/components/button.component";
import { useApplicationStore } from "../../../store/application.store";
import logo from "../../../assets/images/logo-sidebar.png";
import { useNavigate } from "react-router";

type Props = {
  children: ReactNode;
};

type SidebarContextType = {
  isExpanded: boolean;
};

export const SidebarContext = createContext<SidebarContextType>({
  isExpanded: true,
});

export const Sidebar = memo(({ children }: Props) => {
  const user = useApplicationStore((state) => state.user);
  const navigate = useNavigate();
  const logout = useApplicationStore((state) => state.logout);
  const [isExpanded, setIsExpanded] = useState(true);

  const onLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <aside
      className={`h-screen ${
        isExpanded ? "absolute sm:relative w-full z-10 sm:w-2/6 lg:w-3/12" : "w-max"
      }`}
    >
      <nav className="h-full flex flex-col bg-[#1c1c1c] border-r shadow-sm">
        <div className="p-4 pb-2 flex justify-between items-center">
          <img
            src={logo}
            className={`overflow-hidden transition-all ${
              isExpanded ? "w-40 h-28" : "w-0"
            }`}
            alt="Logo"
          />
          <Button
            onClick={() => setIsExpanded((curr) => !curr)}
            className={`p-1.5 mb-5 rounded-lg ${
              isExpanded
                ? "border border-1 border-[#b0a377] text-[#b0a377]"
                : "border border-1 border-[#b0a377] text-[#b0a377] mx-auto"
            }`}
          >
            {isExpanded ? <FaChevronLeft /> : <FaChevronRight />}
          </Button>
        </div>

        <SidebarContext.Provider value={{ isExpanded }}>
          <ul className="flex-1 px-3">{children}</ul>
        </SidebarContext.Provider>

        <div className="border-t border-[#b0a377] flex p-3">
          <div
            className={`
              flex justify-between items-center
              overflow-hidden transition-all ${isExpanded ? "w-52 ml-3" : "w-0"}
          `}
          >
            <div className="leading-4">
              <h4 className="font-semibold text-base text-gray-300">
                {user?.firstName} {user?.lastName}
              </h4>
              <span className="text-xs text-slate-300">{user?.email}</span>
            </div>
            <Button className="text-[#b0a377]" onClick={onLogout}>
              Log out
            </Button>
          </div>
        </div>
      </nav>
    </aside>
  );
});
