import { useState } from "react";
import { ResponseState } from "./response-state";

export const useResponseState = <T>(defaultValues: T) => {
  const [state, setState] = useState<ResponseState<T>>({
    data: defaultValues,
    error: null,
    status: "IDLE",
  });

  const setLoading = () => {
    setState((prevState) => ({ ...prevState, status: "LOADING" }));
  };

  const setSuccess = (data: T | null = null) => {
    setState((prevState) => ({ ...prevState, data, status: "SUCCESS" }));
  };

  const setError = (error: string | null = null) => {
    setState((prevState) => ({ ...prevState, error, status: "ERROR" }));
  };

  return {
    state,
    setState,
    setLoading,
    setSuccess,
    setError,
  };
};
