import { toast } from "react-toastify";
import { Folder } from "../../gallery/models/folder.model";
import { useAxios } from "../useAxios";
import { useResponseState } from "../useResponseState";

export const useCreateFolder = () => {
  const { axios } = useAxios();
  const {
    setLoading,
    setSuccess,
    setError,
    state: folderState,
  } = useResponseState<unknown>(null);

  const createFolder = async (folder: Folder) => {
    try {
      setLoading();
      const resp = await axios.post("gallery/folder", folder);
      toast.success("Folder created successfully!", {
        position: "bottom-right",
      });
      setSuccess();
      return resp.data;
    } catch (error: any) {
      toast.error(error.response.data.message, { position: "bottom-right" });
      setError();
    }
  };

  return { createFolder, folderState };
};
