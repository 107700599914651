import { toast } from "react-toastify";
import { Appointment } from "../../appointment/model/appointment.model";
import { useAxios } from "../useAxios";
import { useResponseState } from "../useResponseState";

export const useCreateAppointment = () => {
  const { axios } = useAxios();
  const {
    setLoading,
    setSuccess,
    setError,
    state: appointmentState,
  } = useResponseState<unknown>(null);

  const createAppointment = async (
    appointment: any,
    authorized: boolean
  ) => {
    try {
      setLoading();
      const appointmentData = {
        ...appointment,
        authorized,
      };
      const resp = await axios.post("appointment", appointmentData);
      toast.success("Appointment created successfully!", {
        position: "bottom-right",
      });
      setSuccess();
      return resp.data;
    } catch (error: any) {
      toast.error(error.response.data.message, { position: "bottom-right" });
      setError();
    }
  };

  return { createAppointment, appointmentState };
};
