type Props = {
  onChange: (value: string) => void;
};

export const SelectStatusForCalendar = ({ onChange }: Props) => {
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value);
  };

  return (
    <select
      className="shadow appearance-none border rounded w-2/3 sm:w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      id="grid-state"
      onChange={handleSelectChange}
    >
      <option value="ALL">All</option>
      <option value="PENDING">Pending</option>
      <option value="CONFIRMED">Confirmed</option>
      <option value="CANCELED">Canceled</option>
    </select>
  );
};
