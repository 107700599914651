import ReactCalendar from "react-calendar";
import "../styles/calendar.css";

type Props = {
  onClickDay: any;
};

export const Calendar = ({ onClickDay }: Props) => {
  const minDate = new Date();
  const currentHour = new Date().getHours();
  if (currentHour >= 17) {
    minDate.setDate(minDate.getDate() + 1);
  }
  const isWeekend = (date: Date) => date.getDay() === 0 || date.getDay() === 6;
  const tileDisabled = ({ date, view }: { date: Date; view: string }) =>
    view === "month" && isWeekend(date);

  return (
    <div className="w-full flex justify-center">
      <ReactCalendar
        minDate={minDate}
        className="p-2 w-full mb-3 react-calendar"
        view="month"
        onClickDay={onClickDay}
        tileDisabled={tileDisabled}
      />
    </div>
  );
};
