import { useEffect, useState } from "react";
import { useGetVideoForHomePage } from "../../api/gallery/useGetVideoForHomePage";

type Video = {
  url: string;
};

export const HomePageVideo = () => {
  const { getVideoForHomePage, videoState } = useGetVideoForHomePage();
  const [video, setVideo] = useState<Video | null>(null);

  const fetchVideo = async () => {
    const resp = await getVideoForHomePage();
    if (resp) {
      setVideo(resp);
    }
  };

  useEffect(() => {
    fetchVideo();
  }, []);

  return video ? (
    <video
      className="pointer-events-none"
      width="w-full"
      height="500"
      autoPlay
      loop
      muted
    >
      <source src={video.url} type="video/mp4" />
    </video>
  ) : null;
};
