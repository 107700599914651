type Props = {
  onChange: (value: number) => void;
  value?: number;
};

export const SelectDuration = ({ onChange, value }: Props) => {
  const durations = [];
  for (let hours = 0; hours <= 5; hours++) {
    for (let minutes = hours === 0 ? 30 : 0; minutes < 60; minutes += 30) {
      if (hours === 5 && minutes === 30) break;
      const totalHours = hours + minutes / 60;
      durations.push(totalHours);
    }
  }

  const handleDurationChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedDuration = parseFloat(event.target.value);
    onChange(selectedDuration);
  };

  return (
    <select
      className="mb-3 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      id="grid-state"
      onChange={handleDurationChange}
      value={value}
    >
      <option value={-1}>---Select duration---</option>
      {durations.map((duration, index) => (
        <option key={index} value={duration}>
          {`${Math.floor(duration)}:${(duration % 1) * 60 < 10 ? "0" : ""}${
            (duration % 1) * 60
          }h`}
        </option>
      ))}
    </select>
  );
};
