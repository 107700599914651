import { useAxios } from "../useAxios";
import { useResponseState } from "../useResponseState";

export const useDeleteImage = () => {
  const { axios } = useAxios();
  const {
    setLoading,
    setSuccess,
    setError,
    state: deleteImageState,
  } = useResponseState<null>(null);

  const deleteImage = async ({
    id,
    public_id,
  }: {
    id: number;
    public_id: string;
  }) => {
    try {
      setLoading();
      await axios.delete("/gallery/image", { data: { id, public_id } });
      setSuccess();
    } catch (error) {
      setError();
    }
  };

  return { deleteImage, deleteImageState };
};
