import { useEffect, useState } from "react";
import Modal from "react-modal";
import { Discount } from "../components/discount.component";
import { HomePageGallery } from "../components/home-page-gallery.component";
import { HomePageVideo } from "../components/home-page-video.component";
import { useGetDiscount } from "../../api/discount/useGetDiscount";
import { Discount as DiscountModel } from "../../admin-panel/discount/models/discount.model";
import { useCookie } from "../hooks/useCookie";
import { Button } from "../../shared/components/button.component";
import { IoMdClose } from "react-icons/io";

export const Home = () => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { getDiscount, discountState } = useGetDiscount();
  const { setCookie, getCookie } = useCookie();
  const [discount, setDiscount] = useState<DiscountModel | null>(null);

  useEffect(() => {
    const fetchDiscount = async () => {
      const resp = await getDiscount();
      if (resp) {
        setDiscount(resp);
      }
    };
    fetchDiscount();
    const modalAlreadyShown = getCookie("modalShown");
    if (!modalAlreadyShown) {
      setModalVisible(true);
    }
  }, []);

  const closeModal = () => {
    setModalVisible(false);
    setCookie("modalShown", "true", 365);
  };

  return (
    <div className="min-h-[100vh]">
      {modalVisible && discount?.isActive && (
        <Modal
          className="w-4/5 md:w-2/3 top-1/2 left-1/2 absolute -translate-x-1/2 -translate-y-1/2 flex items-center flex-col rounded overflow-y-auto bg-[#242422e0] no-scrollbar"
          isOpen={modalVisible}
          onRequestClose={closeModal}
        >
          <Button onClick={closeModal} className="absolute top-0 right-0 m-4">
            <IoMdClose
              size={30}
              className="absolute top-3 right-3 cursor-pointer text-white hover:text-gray-300"
              onClick={closeModal}
            />
          </Button>
          <div
            className="w-full py-12 text-center flex flex-col justify-center items-center bg-[#474d4b] border-8 border-[#5f6865] text-white"
            dangerouslySetInnerHTML={{ __html: discount.text }}
          />
        </Modal>
      )}
      <HomePageVideo />
      <HomePageGallery />
      <Discount />
    </div>
  );
};
