import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Button } from "../../shared/components/button.component";
import {
  CONTACT_FORM_DEFAULT_VALUES,
  CONTACT_VALIDATION_SCHEMA,
} from "../contact.constants";
import { useNewContact } from "../../api/contact/useNewContact";

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  message: string;
};

export const ContactForm = () => {
  const { newContact } = useNewContact();
  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    reset,
  } = useForm<FormValues>({
    defaultValues: CONTACT_FORM_DEFAULT_VALUES,
    resolver: yupResolver(CONTACT_VALIDATION_SCHEMA),
    mode: "onChange",
  });

  const onSubmit = ({
    firstName,
    lastName,
    email,
    phoneNumber,
    message,
  }: FormValues) => {
    newContact({
      firstName,
      lastName,
      email,
      phoneNumber,
      message,
    });
    reset();
  };

  return (
    <form className="flex flex-col items-center w-full">
      <div className="relative h-11 w-4/5 min-w-[200px] mb-3">
        <input
          className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-lg font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-gray-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
          type="text"
          placeholder="First name"
          {...register("firstName")}
        />
        <label className="after:content[''] pointer-events-none absolute text-lg left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[15px] font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
          First name
        </label>
      </div>
      {errors.firstName && (
        <p className="text-red-500 text-sm italic mb-3">
          {errors.firstName?.message}
        </p>
      )}
      <div className="relative h-11 w-4/5 min-w-[200px] mb-3">
        <input
          className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-lg font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-gray-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
          type="text"
          placeholder="Last name"
          {...register("lastName")}
        />
        <label className="after:content[''] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[15px] font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
          Last name
        </label>
      </div>
      {errors.lastName && (
        <p className="text-red-500 text-sm italic mb-3">
          {errors.lastName?.message}
        </p>
      )}
      <div className="relative h-11 w-4/5 min-w-[200px] mb-3">
        <input
          placeholder="example@example.com"
          type="email"
          className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-lg font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-gray-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
          {...register("email")}
        />
        <label className="after:content[''] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[15px] font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
          Email
        </label>
      </div>
      {errors.email && (
        <p className="text-red-500 text-sm italic mb-3">
          {errors.email?.message}
        </p>
      )}
      <div className="relative h-11 w-4/5 min-w-[200px] mb-3">
        <input
          placeholder="+381631234567"
          type="text"
          className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-lg font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-gray-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
          {...register("phoneNumber")}
        />
        <label className="after:content[''] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[15px] font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
          Phone number
        </label>
      </div>
      {errors.phoneNumber && (
        <p className="text-red-500 text-sm italic mb-3">
          {errors.phoneNumber?.message}
        </p>
      )}
      <div className="relative w-4/5 min-w-[200px] mb-3">
        <textarea
          className="peer h-full min-h-[100px] w-full resize-none border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-lg font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-gray-500 focus:outline-0 disabled:resize-none disabled:border-0 disabled:bg-blue-gray-50"
          placeholder=" "
          {...register("message")}
        ></textarea>
        <label className="after:content[''] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[15px] font-normal leading-tight text-blue-gray-500 text-gray-500 transition-all after:absolute after:-bottom-0 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-gray-900 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
          Message
        </label>
      </div>
      {errors.message && (
        <p className="text-red-500 text-sm italic mb-3">
          {errors.message?.message}
        </p>
      )}
      <Button
        className="bg-transparent text-gray-600 border border-[#b0a377] hover:bg-[#b0a377] hover:text-white transition-all duration-300 cursor-pointer py-2 px-4 focus:outline-none focus:shadow-outline mt-10 w-1/2 lg:w-1/3"
        onClick={handleSubmit(onSubmit)}
        disabled={!isValid}
      >
        Send
      </Button>
    </form>
  );
};
