import { useNavigate } from "react-router-dom";

type Props = {
  text: string;
  redirect: string;
  isActive: boolean;
  setIsMenuOpen: (value: boolean) => void;
};

export const NavbarItemComponent = ({
  text,
  redirect,
  isActive,
  setIsMenuOpen,
}: Props) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(redirect);
    setIsMenuOpen(false);
  };

  return (
    <li
      className="w-full text-center py-3 md:py-0 px-3 cursor-pointer"
      onClick={handleOnClick}
    >
      <span>{text}</span>
    </li>
  );
};
