import { useState } from "react";
import { Folder } from "../../../gallery/models/folder.model";
import { Image as ImageModel } from "../../../gallery/models/image.model";
import { UploadInput } from "./upload-input.component";
import { Button } from "../../../shared/components/button.component";
import { useUploadImage } from "../../../api/gallery/useUploadImage";

type Image = {
  id: number;
  url: string;
  public_id: string;
};

type Props = {
  folder: Folder;
  setImages: (value: React.SetStateAction<Image[]>) => void;
};

export const UploadImageForFolder = ({ folder, setImages }: Props) => {
  const { uploadImage, uploadState } = useUploadImage();
  const [image, setImage] = useState<ImageModel | null>(null);

  const handleNewImage = async () => {
    if (!image?.image) {
      return;
    }

    const formData = new FormData();
    formData.append("file", image.image as File);
    formData.append("folderId", folder.id!.toString());
    formData.append("folderName", folder.name);

    const resp = await uploadImage(formData);
    if (resp) {
      setImages((prev) => [...prev, resp]);
      setImage(null);
    }
  };

  return (
    <div className="flex w-full flex-col items-center">
      <div className="flex flex-col p-5 w-full sm:w-3/4 lg:w-1/2 items-center">
        <UploadInput
          fileType="image"
          onChange={(value: File) => setImage({ ...image, image: value })}
          value={image?.image!}
          className="flex items-center justify-center w-full"
        />
        {image && (
          <Button
            className="bg-[#000000b3] hover:bg-[#171717b3] w-max mt-3 mb-10 text-[#b0a377] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={handleNewImage}
            disabled={uploadState.status === "LOADING"}
          >
            {uploadState.status === "LOADING" ? "Uploading..." : "Upload"}
          </Button>
        )}
      </div>
    </div>
  );
};
