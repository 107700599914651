import { toast } from "react-toastify";
import { Appointment } from "../../appointment/model/appointment.model";
import { useAxios } from "../useAxios";
import { useResponseState } from "../useResponseState";

export const useGetAppointmentsForDay = () => {
  const { axios } = useAxios();
  const {
    setLoading,
    setSuccess,
    setError,
    state: appointmentsForDayState,
  } = useResponseState<Appointment[]>([]);

  const getAllAppointmentsForDay = async (date: Date) => {
    try {
      setLoading();
      const resp = await axios.post("/appointment/day", {date});
      setSuccess();
      return resp.data;
    } catch (error: any) {
      toast.error(error.response.data.message, { position: "bottom-right" });
      setError();
    }
  };
  return { getAllAppointmentsForDay, appointmentsForDayState };
};
