import { useState } from "react";
import { add, format } from "date-fns";
import { Appointment } from "../model/appointment.model";
import { useApplicationStore } from "../../store/application.store";
import { ServiceType } from "../../admin-panel/service-type/models/service-type.model";
import moment from "moment";
import { Button } from "../../shared/components/button.component";
import PulseLoader from "react-spinners/PulseLoader";

type Props = {
  date: Date;
  appointments: Appointment[];
  onSelectTime: (time: Date) => void;
  selectedServiceType: ServiceType;
  isLoading: boolean;
};

export const TimeSelection = ({
  date,
  appointments,
  onSelectTime,
  selectedServiceType,
  isLoading,
}: Props) => {
  const user = useApplicationStore((state) => state.user);
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);
  const getTimes = () => {
    const [startHour, endHour] = user ? [8, 20] : [8, 16];
    const day = new Date(date);
    day.setHours(0, 0, 0, 0);
    const beggining = add(day, { hours: startHour });
    const end = add(day, { hours: endHour });
    const interval = 30;
    const times = [];

    for (let i = beggining; i <= end; i = add(i, { minutes: interval })) {
      times.push(i);
    }

    return times;
  };

  const times = getTimes();

  const isTimeAvailable = (time: Date) => {
    const serviceDuration = selectedServiceType.duration;
    const appointmentEnd = add(time, { hours: serviceDuration });

    const isAvailable = !appointments.some((appointment) => {
      const formattedStartDate = moment(
        appointment.startDate,
        "YYYY-MM-DD HH:mm:ss"
      ).toDate();
      const formattedEndDate = moment(
        appointment.endDate!,
        "YYYY-MM-DD HH:mm:ss"
      ).toDate();
      return (
        (time >= formattedStartDate && time < formattedEndDate) ||
        (appointmentEnd > formattedStartDate &&
          appointmentEnd <= formattedEndDate) ||
        (time <= formattedStartDate && appointmentEnd >= formattedEndDate)
      );
    });

    return isAvailable;
  };

  return (
    <>
      {isLoading ? (
        <PulseLoader color="#b0a377" />
      ) : (
        <div className="text-center my-5">
          {times.map((time, i) => {
            const isTaken = !isTimeAvailable(time);

            const isSelected =
              selectedTime &&
              format(time, "HH:mm") === format(selectedTime, "HH:mm");

            return (
              <Button
                key={i}
                className={`rounded-xl m-2 p-3 ${
                  isTaken
                    ? "bg-[#373737b3] text-[#ababab] cursor-not-allowed"
                    : isSelected
                    ? "bg-[#000000b3] text-[#b0a377]"
                    : "bg-gray-300 hover:bg-gray-400"
                }`}
                onClick={() => {
                  onSelectTime(time);
                  setSelectedTime(time);
                }}
                disabled={isTaken}
              >
                {format(time, "kk:mm")}
              </Button>
            );
          })}
        </div>
      )}
    </>
  );
};
