import { toast } from "react-toastify";
import { Contact } from "../../contact/model/contact.model";
import { useAxios } from "../useAxios";
import { useResponseState } from "../useResponseState";

export const useNewContact = () => {
  const { axios } = useAxios();
  const { setLoading, setSuccess, setError } = useResponseState<unknown>(null);

  const newContact = async (folder: Contact) => {
    try {
      setLoading();
      await axios.post("contact", folder);
      toast.success("Sent successfully!", {
        position: "bottom-right",
      });
      setSuccess();
    } catch (error: any) {
      toast.error(error.response.data.message, { position: "bottom-right" });
      setError();
    }
  };
  return { newContact };
};
