import * as yup from "yup";

const VALID_EMAIL_FORMAT = "Field should have valid email format!";
const VALID_PHONE_NUMBER = "Phone number is not valid!";
const REQUIRED = "Field is required!";
const phoneRegEx =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

export const CONTACT_VALIDATION_SCHEMA = yup.object({
  firstName: yup.string().required(REQUIRED),
  lastName: yup.string().required(REQUIRED),
  email: yup.string().email(VALID_EMAIL_FORMAT).required(REQUIRED),
  phoneNumber: yup
    .string()
    .matches(phoneRegEx, VALID_PHONE_NUMBER)
    .required(REQUIRED),
  message: yup.string().required(REQUIRED),
});

export const CONTACT_FORM_DEFAULT_VALUES = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  message: "",
};
