import { toast } from "react-toastify";
import { useAxios } from "../useAxios";
import { useResponseState } from "../useResponseState";

export const useUploadImageForHomePage = () => {
  const { axios } = useAxios();
  const {
    setLoading,
    setSuccess,
    setError,
    state: uploadState,
  } = useResponseState<unknown>(null);

  const uploadImage = async (image: FormData) => {
    try {
      setLoading();
      const resp = await axios.post("/gallery/image-for-home-page", image, {
        headers: { "Content-Type": "mutipart/form-data" },
      });
      toast.success("Image uploaded successfully!", {
        position: "bottom-right",
      });
      setSuccess();
      return resp.data;
    } catch (error: any) {
      toast.error(error.response.data.message, { position: "bottom-right" });
      setError();
    }
  };

  return { uploadImage, uploadState };
};
