import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

export const ContactContent = () => {
  return (
    <>
      <h1 className="text-gray-600 font-bold text-3xl text-center tracking-widest">
        GET IN TOUCH
      </h1>
      <p className="mt-8 text-gray-500 font-thin text-center text-3xl italic tracking-widest">
        Contact us by email, phone or through our web form below.
      </p>
      <hr className="h-px my-14 bg-gray-300 border-0 w-4/5" />
      <div className="flex flex-col md:flex-row w-full lg:w-4/5 items-center justify-evenly mb-20">
        <div className="max-w-[90%] sm:max-w-[70%] md:max-w-80 lg:max-w-96 mb-10 md:mb-0">
          <span className="flex text-2xl lg:text-3xl justify-center items-center mb-10 bg-gray-800 text-white p-8 tracking-wide border-8 border-gray-300">
            <FaPhone className="mx-3" />
            <a href="tel:0631234567">063 123 45 67</a>
          </span>
          <h3 className="text-gray-600 text-lg mb-3 tracking-wide">
            Lorem ipsum
          </h3>
          <p className="text-gray-600">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti
            incidunt commodi magni facere animi voluptatem totam explicabo esse
            aut provident, sed sit facilis, necessitatibus obcaecati recusandae
            nisi odit? Officia, minima?
          </p>
        </div>
        <div className="max-w-[90%] sm:max-w-[70%] md:max-w-80 lg:max-w-96 mb-10 md:mb-0">
          <span className="flex text-2xl lg:text-3xl justify-center items-center mb-10 bg-gray-800 text-white p-8 tracking-wide border-8 border-gray-300">
            <MdEmail className="mx-3" />
            <a href="mailto:test@test.com">test@test.com</a>
          </span>
          <h3 className="text-gray-600 text-lg mb-3 tracking-wide">
            Lorem ipsum
          </h3>
          <p className="text-gray-600">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti
            incidunt commodi magni facere animi voluptatem totam explicabo esse
            aut provident, sed sit facilis, necessitatibus obcaecati recusandae
            nisi odit? Officia, minima?
          </p>
        </div>
      </div>
    </>
  );
};
