export const Car = () => {
  return (
    <svg
      fill="#b0a377"
      width="350px"
      height="350px"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M 9.5 6 C 8.179688 6 7.003906 6.859375 6.625 8.125 L 5.25 12.71875 L 3.3125 12.0625 L 2.6875 13.9375 L 4.65625 14.59375 L 4.03125 16.71875 C 4.007813 16.808594 3.996094 16.90625 4 17 L 4 23 C 4 23.03125 4 23.0625 4 23.09375 L 4 25 C 4 25.550781 4.449219 26 5 26 L 8 26 L 8.65625 24 L 13.90625 24 L 23.84375 25.53125 L 24 26 L 27 26 C 27.011719 26 27.019531 26 27.03125 26 C 27.273438 26 27.507813 25.910156 27.6875 25.75 C 27.699219 25.738281 27.707031 25.730469 27.71875 25.71875 C 27.730469 25.707031 27.742188 25.699219 27.75 25.6875 C 27.902344 25.515625 27.992188 25.292969 28 25.0625 C 28 25.042969 28 25.019531 28 25 L 28 24.96875 C 28.003906 24.925781 28.003906 24.886719 28 24.84375 L 27.15625 19 L 29 19 L 29 17 L 26.625 17 L 24.5 12.65625 L 25.375 9.71875 C 25.5 9.316406 25.363281 8.882813 25.03125 8.625 L 23.09375 7.1875 C 22.945313 7.082031 22.773438 7.015625 22.59375 7 L 9.5625 6 C 9.542969 6 9.519531 6 9.5 6 Z M 9.5 8 L 22.125 8.96875 L 23.25 9.8125 L 22.46875 12.4375 C 22.390625 12.6875 22.414063 12.957031 22.53125 13.1875 L 24.03125 16.25 L 17.90625 14.125 L 17.78125 14.0625 L 17.65625 14.0625 L 7.21875 13.09375 L 8.53125 8.71875 C 8.660156 8.289063 9.054688 8 9.5 8 Z M 6.625 15.0625 L 17.40625 16.0625 L 17.46875 16.0625 L 25.09375 18.75 L 25.8125 23.78125 L 14.21875 22 C 14.167969 21.996094 14.113281 21.996094 14.0625 22 L 12.90625 22 L 13.34375 21 L 17.65625 21.625 L 20.25 22 L 19 20 L 12 19 L 10.75 22 L 6 22 L 6 17.1875 Z M 8.5 16 C 7.671875 16 7 16.671875 7 17.5 C 7 18.328125 7.671875 19 8.5 19 C 9.328125 19 10 18.328125 10 17.5 C 10 16.671875 9.328125 16 8.5 16 Z M 20.875 18.75 C 20.539063 19.691406 21.027344 20.726563 21.96875 21.0625 C 22.910156 21.398438 23.945313 20.910156 24.28125 19.96875 Z" />
    </svg>
  );
};
