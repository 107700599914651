import { ContactContent } from "../components/contact-content.component";
import { ContactForm } from "../components/contact-form.component";

export const Contact = () => {
  return (
    <div className="flex w-full mt-16 flex-col items-center">
      <ContactContent />
      <div className="bg-gray-50 w-4/5 sm:w-3/4 md:w-3/5 lg:w-1/3 border-8 border-gray-300 p-10 mb-36">
        <ContactForm />
      </div>
    </div>
  );
};
