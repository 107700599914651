import { ImageUploadFormForHomePage } from "../components/image-upload-form-for-home-page.component";
import { ImagesForHomePage } from "../components/images-for-home-page.component";
import { VideoForHomePage } from "../components/video-for-home-page.component";
import { VideoUploadFormForHomePage } from "../components/video-upload-form-for-home-page.component";

export const GalleryHome = () => {
  return (
    <div className="flex w-full flex-col items-center overflow-y-auto">
      <div className="flex w-full flex-col items-center">
        <h1 className="text-gray-600 font-bold text-center text-xl sm:text-2xl md:text-3xl lg:text-4xl my-10">
          Video
        </h1>
        <VideoForHomePage />
        <hr className="h-px my-10 bg-gray-300 border-0 w-4/5" />
      </div>
      <div className="flex w-full flex-col items-center">
        <h1 className="text-gray-600 font-bold text-center text-xl sm:text-2xl md:text-3xl lg:text-4xl mb-10">
          Images
        </h1>
        <ImagesForHomePage />
        <hr className="h-px my-10 bg-gray-300 border-0 w-4/5" />
      </div>
      <div className="flex w-full flex-col items-center">
        <h1 className="text-gray-600 font-bold text-center text-xl sm:text-2xl md:text-3xl lg:text-4xl mb-10">
          Add new video for home page
        </h1>
        <VideoUploadFormForHomePage />
        <hr className="h-px my-10 bg-gray-300 border-0 w-4/5" />
      </div>
      <div className="flex w-full flex-col items-center">
        <h1 className="text-gray-600 font-bold text-center text-xl sm:text-2xl md:text-3xl lg:text-4xl mb-10">
          Add new image for home page
        </h1>
        <ImageUploadFormForHomePage />
      </div>
    </div>
  );
};
