import { toast } from "react-toastify";
import { ServiceType } from "../../admin-panel/service-type/models/service-type.model";
import { useAxios } from "../useAxios";
import { useResponseState } from "../useResponseState";

export const useGetAllServiceTypes = () => {
  const { axios } = useAxios();
  const {
    setLoading,
    setSuccess,
    setError,
    state: serviceTypeState,
  } = useResponseState<ServiceType[]>([]);

  const getAllServiceTypes = async () => {
    try {
      setLoading();
      const resp = await axios.get("/service-type");
      setSuccess();
      return resp.data;
    } catch (error: any) {
      toast.error(error.response.data.message, { position: "bottom-right" });
      setError();
    }
  };

  return { getAllServiceTypes, serviceTypeState };
};
