import { useEffect, useState } from "react";
import { useGetAllFolders } from "../../../api/gallery/useGetAllFolders";
import { FolderInputForm } from "./folder-input-form.component";
import { Folder } from "./folder.component";

export const FolderManager = () => {
  const { getAllFolders, folderState } = useGetAllFolders();
  const [folders, setFolders] = useState<{ name: string; deleting: boolean }[]>(
    []
  );

  const fetchFolders = async () => {
    const response = await getAllFolders();
    setFolders(response.map((folder: any) => ({ ...folder, deleting: false })));
  };

  useEffect(() => {
    fetchFolders();
  }, []);

  const refetchFolders = async () => {
    await fetchFolders();
  };
  return (
    <>
      <FolderInputForm onFolderCreated={refetchFolders} />
      <Folder
        folders={folders}
        setFolders={setFolders}
        folderState={folderState}
      />
    </>
  );
};
