import { useState } from "react";
import { Button } from "../../../shared/components/button.component";
import { FormInput } from "../../../shared/components/form-input.component";
import { ServiceType } from "../models/service-type.model";
import { SERVICE_TYPE_FORM_DEFAULT_VALUES } from "../../admin-panel.constants";
import { useCreateServiceType } from "../../../api/service-type/useCreateServiceType";
import { SelectDuration } from "./select-duration.component";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

export const ServiceTypeForm = () => {
  const queryClient = useQueryClient();
  const { createServiceType, serviceTypeState } = useCreateServiceType();
  const [serviceType, setServiceType] = useState<ServiceType>(
    SERVICE_TYPE_FORM_DEFAULT_VALUES
  );

  const handleNewServiceType = async () => {
    if (serviceType.duration < 0.5 || serviceType.category.trim() === "") {
      toast.error("Please fill all the fields!", { position: "bottom-right" });
      return;
    }
    const resp = await createServiceType(serviceType);
    if (resp) {
      setServiceType(SERVICE_TYPE_FORM_DEFAULT_VALUES);
      queryClient.invalidateQueries("allServiceTypes");
    }
  };

  return (
    <div className="flex flex-col p-5 w-full sm:w-3/4 lg:w-1/2">
      <form className="text-center">
        <FormInput
          value={serviceType.category}
          type="text"
          placeholder="Category"
          onChange={(value) =>
            setServiceType({ ...serviceType, category: value })
          }
        />
        <SelectDuration
          onChange={(value) =>
            setServiceType({ ...serviceType, duration: value })
          }
          value={serviceType.duration}
        />
        <Button
          className="bg-[#000000b3] hover:bg-[#171717b3] text-[#b0a377] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={handleNewServiceType}
          disabled={serviceTypeState.status === "LOADING"}
        >
          {serviceTypeState.status === "LOADING"
            ? "Creating service type"
            : "Add category"}
        </Button>
      </form>
    </div>
  );
};
