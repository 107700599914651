import { useContext } from "react";
import { SidebarContext } from "./sidebar.component";
import { useNavigate } from "react-router-dom";

type Props = {
  icon: JSX.Element;
  text: string;
  redirect: string;
  isActive: boolean;
};

export function SidebarItem({ icon, text, redirect, isActive }: Props) {
  const { isExpanded } = useContext(SidebarContext);
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(redirect);
  };

  return (
    <li
      className={`
          relative flex items-center max-h-[41px] py-2 px-3 my-1
          font-medium rounded-md cursor-pointer text-[#b0a377]
          transition-colors group 
          ${isActive ? "bg-[#000000b3] text-[#b0a377]" : "hover:bg-gray-500"}`}
      onClick={handleOnClick}
    >
      {icon}
      <span
        className={`overflow-hidden transition-all ${
          isExpanded ? "w-52 ml-3" : "w-0"
        }`}
      >
        {text}
      </span>

      {!isExpanded && (
        <div
          className={`
            absolute left-full rounded-md px-2 py-1 ml-6
            bg-[#1c1c1c] text-[#b0a377] text-sm text-nowrap 
            invisible opacity-20 -translate-x-3 transition-all
            group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
        `}
        >
          {text}
        </div>
      )}
    </li>
  );
}
