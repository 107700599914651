import { useEffect, useState } from "react";
import { useGetImagesForHomePage } from "../../api/gallery/useGetImagesForHomePage";
import Skeleton from "react-loading-skeleton";
import { Button } from "../../shared/components/button.component";
import { Link } from "react-router-dom";

type Image = {
  url: string;
};

export const HomePageGallery = () => {
  const { getImagesForHomePage, imageState } = useGetImagesForHomePage();
  const [images, setImages] = useState<Image[] | null>(null);

  const fetchImages = async () => {
    const resp = await getImagesForHomePage();
    if (resp) {
      setImages(resp);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  return (
    <>
      {images?.length === 9 ? (
        <div className="flex flex-col items-center mt-16 overflow-hidden">
          <h1 className="text-gray-600 font-bold text-3xl text-center tracking-widest">
            GALLERY
          </h1>
          <p className="mt-8 text-gray-500 font-thin text-center text-3xl italic tracking-widest">
            Over 10 years with you
          </p>
          <hr className="h-px my-14 bg-gray-300 border-0 w-4/5" />
          <div className="flex flex-col md:flex-row md:gap-10 mb-20 lg:p-10">
            <div className="w-full md:w-1/2 grid grid-cols-3 gap-3 overflow-hidden">
              {imageState.status === "LOADING"
                ? Array.from({ length: 9 }).map((_, i) => (
                    <Skeleton key={i} className="w-[175px] h-[175px]" />
                  ))
                : images &&
                  images.map((image, i) => (
                    <img
                      key={i}
                      src={image.url}
                      className="object-fit w-full h-full"
                      style={{ aspectRatio: "1 / 1" }}
                    />
                  ))}
            </div>
            <div className="w-full h-full md:w-1/2 py-20 flex flex-col justify-center items-center md:items-start">
              <h1 className="mb-5 text-3xl text-[#5c5e62]">Gallery</h1>
              <p className="text-[#5c5e62] mb-5 text-center md:text-left">
                Discover our diverse range of services captured in these images,
                showcasing our expertise in various areas such as
                decarbonization, disk servicing, and both major and minor
                vehicle maintenance.
              </p>
              <Link
                to="/gallery"
                className="bg-[#6b6957] w-max text-white font-bold text-lg px-6 py-3 rounded-[30px]"
              >
                Show more
              </Link>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
