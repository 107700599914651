import { useNavigate } from "react-router-dom";
import { useApplicationStore } from "../../store/application.store";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  LOGIN_FORM_DEFAULT_VALUES,
  LOGIN_VALIDATION_SCHEMA,
} from "../auth.constants";
import { FaEnvelope, FaLock } from "react-icons/fa";
import { Button } from "../../shared/components/button.component";
import { ToastContainer } from "react-toastify";

type FormValues = {
  email: string;
  password: string;
};

export const Login = () => {
  const navigate = useNavigate();
  const signIn = useApplicationStore((state) => state.login);

  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
  } = useForm<FormValues>({
    defaultValues: LOGIN_FORM_DEFAULT_VALUES,
    resolver: yupResolver(LOGIN_VALIDATION_SCHEMA),
    mode: "onChange",
  });

  const login = async ({ email, password }: FormValues) => {
    const { user } = await signIn({ email, password });
    if (user) {
      navigate("/discount");
      return;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmit(login)();
    }
  };

  return (
    <>
      <div className="w-full h-screen bg-[#e9ecef] flex items-center justify-center flex-col">
        <p className="text-3xl mb-3 text-[#373737b3]">
          <b className="text-[#000000b3]">Master</b> engine
        </p>
        <div className="w-full max-w-xs" onKeyDown={(e) => handleKeyDown(e)}>
          <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="mb-4 relative">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <div className="flex items-center">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder="Email"
                  {...register("email")}
                />
                <FaEnvelope className="absolute right-2 text-gray-500" />
              </div>
              {errors.email && (
                <p className="text-red-500 text-xs italic">
                  {errors.email?.message}
                </p>
              )}
            </div>
            <div className="mb-6 relative">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <div className="flex items-center">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="********"
                  {...register("password")}
                />
                <FaLock className="absolute right-2 text-gray-500" />
              </div>
              {errors.password && (
                <p className="text-red-500 text-xs italic">
                  {errors.password?.message}
                </p>
              )}
            </div>
            <div className="flex items-center justify-between">
              <Button
                className="bg-[#000000b3] hover:bg-[#171717b3] text-[#b0a377] cursor-pointer font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={handleSubmit(login)}
                disabled={!isValid}
              >
                Sign In
              </Button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
