import { useEffect, useState } from "react";
import { useGetImagesByFolder } from "../../../api/gallery/useGetImagesByFolder";
import Skeleton from "react-loading-skeleton";
import { GalleryTypePicker } from "../../../gallery/components/gallery-type-picker";
import { useDeleteImage } from "../../../api/gallery/useDeleteImage";
import { Button } from "../../../shared/components/button.component";
import { Folder } from "../../../gallery/models/folder.model";
import { UploadImageForFolder } from "./upload-image-for-folder.component";

type Image = {
  id: number;
  url: string;
  public_id: string;
};

export const GalleryManager = () => {
  const { getImagesByFolder, imageState } = useGetImagesByFolder();
  const { deleteImage, deleteImageState } = useDeleteImage();
  const [selectedButton, setSelectedButton] = useState<Folder | null>(null);
  const [images, setImages] = useState<Image[]>([]);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);

  const fetchImages = async (folder: Folder) => {
    const resp = await getImagesByFolder(folder.id!);
    setImages(resp);
  };

  useEffect(() => {
    if (selectedButton) {
      fetchImages(selectedButton);
    }
  }, [selectedButton]);

  const deleteImageHandler = async (image: Image) => {
    await deleteImage(image);
  };

  useEffect(() => {
    if (deleteImageState.status === "SUCCESS") {
      setImages(
        images.filter((image) => image.public_id !== selectedImage!.public_id)
      );
      setSelectedImage(null);
    }
  }, [deleteImageState.status]);

  return (
    <>
      <h1 className="text-gray-600 font-bold text-center text-xl sm:text-2xl md:text-3xl lg:text-4xl my-10">
        Gallery
      </h1>
      <div className="flex w-full mt-16 flex-col items-center">
        <GalleryTypePicker onButtonSelect={setSelectedButton} />
        {imageState.status === "SUCCESS" && (
          <div className="w-3/4 sm:w-2/3 md:w-1/2 lg:w-3/4 mt-10">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 mb-20">
              {images.map((image) => (
                <img
                  key={image.id}
                  src={image.url}
                  className={`w-full h-52 sm:h-60 md:h-64 fit-object ${
                    selectedImage?.id === image.id &&
                    "border-8 border-[#b0a377]"
                  }`}
                  onClick={() => setSelectedImage(image)}
                />
              ))}
            </div>
          </div>
        )}
        {imageState.status === "LOADING" && (
          <div className="w-3/4 sm:w-2/3 md:w-1/2 lg:w-3/4 mt-10">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 mb-20">
              {Array.from({ length: 6 }).map((_, i) => (
                <Skeleton
                  key={i}
                  className="w-full h-52 sm:h-60 md:h-64 fit-object"
                />
              ))}
            </div>
          </div>
        )}
      </div>
      {selectedImage ? (
        <Button
          className="bg-[#000000b3] text-[#b0a377] p-3 rounded mb-10"
          onClick={() => deleteImageHandler(selectedImage)}
        >
          {deleteImageState.status === "LOADING" ? "Deleting..." : "Delete"}
        </Button>
      ) : null}
      {imageState.status === "SUCCESS" && selectedButton && (
        <UploadImageForFolder folder={selectedButton} setImages={setImages} />
      )}
    </>
  );
};
