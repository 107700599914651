import { toast } from "react-toastify";
import { useAxios } from "../useAxios";
import { useResponseState } from "../useResponseState";

export const useDeleteServiceType = () => {
  const { axios } = useAxios();
  const {
    setLoading,
    setSuccess,
    setError,
    state: serviceTypeState,
  } = useResponseState<unknown>(null);

  const deleteServiceType = async (id: number) => {
    try {
      setLoading();
      await axios.delete(`/service-type/${id}`);
      toast.success("Service type deleted successfully!", {
        position: "bottom-right",
      });
      setSuccess();
      return true;
    } catch (error: any) {
      toast.error(error.response.data.message, {
        position: "bottom-right",
      });
      setError();
      return false;
    }
  };

  return { deleteServiceType, serviceTypeState };
};
