import { AllServiceTypes } from "../components/all-service-types.component";
import { ServiceTypeForm } from "../components/service-type-form.component";

export const ServiceType = () => {
  return (
    <div className="flex w-full flex-col items-center overflow-y-auto">
      <h1 className="text-gray-600 font-bold text-center text-xl sm:text-2xl md:text-3xl lg:text-4xl my-10">
        New Service Type
      </h1>
      <ServiceTypeForm />
      <hr className="h-px my-10 bg-gray-300 border-0 w-4/5" />
      <div className="flex w-full flex-col items-center">
        <h1 className="text-gray-600 font-bold text-center text-xl sm:text-2xl md:text-3xl lg:text-4xl mb-10">
          All Service Types
        </h1>
        <AllServiceTypes />
      </div>
    </div>
  );
};
