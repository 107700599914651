import { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { toast } from "react-toastify";
import { ServiceType } from "../../admin-panel/service-type/models/service-type.model";
import { useCreateAppointment } from "../../api/appointment/useCreateAppointment";
import { useGetAppointmentsForDay } from "../../api/appointment/useGetAppointmentsForDay";
import { Button } from "../../shared/components/button.component";
import { FormInput } from "../../shared/components/form-input.component";
import { useApplicationStore } from "../../store/application.store";
import { APPOINTMENT_FORM_DEFAULT_VALUES } from "../appointment.constants";
import { Appointment } from "../model/appointment.model";
import { AdditionalInfo } from "./additional-info.component";
import { Calendar } from "./calendar.component";
import { SelectServiceType } from "./select-service-type.component";
import { TimeSelection } from "./time-selection.component";

export const AppointmentForm = () => {
  const user = useApplicationStore((state) => state.user);
  const { getAllAppointmentsForDay, appointmentsForDayState } =
    useGetAppointmentsForDay();
  const { createAppointment, appointmentState } = useCreateAppointment();
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [appointment, setAppointment] = useState<Appointment>(
    APPOINTMENT_FORM_DEFAULT_VALUES
  );
  const [showTimes, setShowTimes] = useState<boolean>(false);
  const [serviceTypes, setServiceTypes] = useState<ServiceType>(
    {} as ServiceType
  );

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  const handleNewAppointment = async () => {
    const trimmedAppointment = {
      ...appointment,
      firstName: appointment.firstName.trim(),
      lastName: appointment.lastName.trim(),
      car: {
        model: appointment.car.model.trim(),
      },
      email: appointment.email ? appointment.email.trim() : undefined,
      additionalInfo: appointment.additionalInfo?.trim(),
      startDate: appointment.startDate?.toString(),
    };

    const isInvalidAppointment =
      !trimmedAppointment.firstName ||
      !trimmedAppointment.lastName ||
      !trimmedAppointment.car.model ||
      !trimmedAppointment.serviceType.category ||
      (!user && !trimmedAppointment.email) ||
      !trimmedAppointment.startDate;
    // trimmedAppointment.startDate.getHours() < 8;

    if (isInvalidAppointment) {
      toast.error("Please fill all the fields!", { position: "bottom-right" });
      return;
    }

    if (
      trimmedAppointment.email &&
      !emailRegex.test(trimmedAppointment.email)
    ) {
      toast.error("Please enter a valid email address!", {
        position: "bottom-right",
      });
      return;
    }

    const resp = await createAppointment(trimmedAppointment, !!user);
    if (resp) {
      setAppointment(APPOINTMENT_FORM_DEFAULT_VALUES);
      setShowTimes(false);
    }
  };

  const handleInputChange = (name: string, value: string) => {
    setAppointment((prev: Appointment | null) => ({
      ...(prev as Appointment),
      [name]: value,
    }));
  };

  const handleCarModelChange = (value: string) => {
    setAppointment((prev: Appointment | null) => ({
      ...(prev as Appointment),
      car: { model: value },
    }));
  };

  const handleServiceTypeChange = (selectedServiceType: ServiceType) => {
    setAppointment((prev: Appointment | null) => ({
      ...(prev as Appointment),
      serviceType: selectedServiceType,
      startDate: null,
    }));
    setServiceTypes(selectedServiceType);
    setShowTimes(false);
  };

  const handleAdditionalInfoChange = (value: string) => {
    setAppointment((prev: Appointment | null) => ({
      ...(prev as Appointment),
      additionalInfo: value,
    }));
  };

  const handleDateSelection = (date: any) => {
    setAppointment((prev: Appointment | null) => ({
      ...(prev as Appointment),
      startDate: date,
    }));
    setShowTimes(true);
  };

  const handleTimeSelection = (time: Date) => {
    setAppointment((prev: Appointment | null) => ({
      ...(prev as Appointment),
      startDate: time,
    }));
  };

  useEffect(() => {
    if (appointment?.startDate) {
      const fetchAppointments = async () => {
        const appointmentsForDay = await getAllAppointmentsForDay(
          appointment.startDate!
        );
        setAppointments(
          appointmentsForDay.filter(
            (appointment: Appointment) => appointment.status === "CONFIRMED"
          )
        );
      };
      fetchAppointments();
    }
  }, [appointment?.startDate]);

  return (
    <div className="flex flex-col p-5 w-full sm:w-3/4 lg:w-1/2">
      <form className="text-center">
        <div className="flex gap-3">
          <FormInput
            value={appointment?.firstName}
            type="text"
            placeholder="First name"
            onChange={(value) => handleInputChange("firstName", value)}
          />
          <FormInput
            value={appointment?.lastName}
            type="text"
            placeholder="Last name"
            onChange={(value) => handleInputChange("lastName", value)}
          />
        </div>
        <FormInput
          value={appointment?.car?.model}
          type="text"
          placeholder="Car model"
          onChange={handleCarModelChange}
        />
        {!user ? (
          <FormInput
            value={appointment?.email!}
            type="email"
            placeholder="Email"
            onChange={(value) => handleInputChange("email", value)}
          />
        ) : null}
        <SelectServiceType
          onChange={handleServiceTypeChange}
          value={appointment.serviceType}
        />
        <AdditionalInfo
          onChange={handleAdditionalInfoChange}
          value={appointment?.additionalInfo || ""}
        />
        <Calendar onClickDay={handleDateSelection} />
        {showTimes && (
          <TimeSelection
            date={appointment?.startDate as Date}
            appointments={appointments}
            onSelectTime={handleTimeSelection}
            selectedServiceType={serviceTypes}
            isLoading={appointmentsForDayState.status === "LOADING"}
          />
        )}
        <Button
          className="bg-[#000000b3] hover:bg-[#171717b3] text-[#b0a377] font-bold mb-12 py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={handleNewAppointment}
          disabled={appointmentState.status === "LOADING"}
        >
          {appointmentState.status === "LOADING" ? "Creating..." : "Create"}
        </Button>
      </form>
    </div>
  );
};
